button#outlineBtn:focus {
    color: #5050DB;
    outline-style: solid;
    outline-width: 2px;
    outline-color: #5050DB;
}

button#iconButtons:focus {
    color: #5050DB;
    outline-style: solid;
    outline-width: 2px;
    outline-color: #5050DB;
}

.react-calendar {
    height: 250px !important;
    border: none !important;
    background: transparent !important;
    color: #201E5A !important;
    font-size: 14px;
}

.react-calendar__tile--active {
    background-color: #E0E0FB !important;
    border-radius: 50% !important;
    color: #201E5A !important;
}

.react-calendar__navigation {
    display: none !important;
}

#optionsToggle button::after {
    display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

#customArccordionItem {
    border-radius: 20px !important;
    box-shadow: none !important;
}

.customAccordion {
    background-color: #F7F7FD !important;
    border-radius: 20px !important;
    box-shadow: none !important;
}