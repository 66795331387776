@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Baloo 2", sans-serif;
  background-color: #f7f7fd;
}

html,
body {
  scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
  border-radius: 0 20px 20px 0px;
  width: 90% !important;
  background-color: #f7f7fd;
  color: #6666ea !important;
}

.active p {
  color: #6666ea;
}

.dashIconActive {
  color: #6666ea;
}

.ps-menu-button:hover {
  border-radius: 0 20px 20px 0px;
  background-color: #f7f7fd !important;
  color: #000 !important;
  width: 90%;
}

/* sidebar scroll */
#mySidebar::-webkit-scrollbar {
  width: 4px !important;
  background-color: #f5f5f5 !important;
  display: none !important;
}

#mySidebar::-webkit-scrollbar-thumb {
  background-color: #7f7f7f66 !important;
  border-radius: 10px !important;
}

/* calendar scroll */
#calendarScroll::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#calendarScroll::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}

/* class page scroll custom */
#horizontalTab::-webkit-scrollbar {
  height: 4px;
  background-color: #f5f5f5;
}

#horizontalTab::-webkit-scrollbar-thumb {
  background-color: #65656547;
  border-radius: 10px;
}
